import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import ChatRoundedIcon from "@material-ui/icons/ChatRounded";
import ParentMenu from "ui/layout/Menu/components/ParentMenu/ParentMenu";
import { CustomMenuItemLink } from "../CustomMenuItemLink/CustomMenuItemLink";
import {
  MENU_ITEM_IDS,
  SUB_MENU_CONFIG,
  TOOGLE_PARENT_MENU_KEYS,
} from "../../constants/Menu.constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { useInboxStore } from "features/OmniChannel/modules/Inbox/store/useInboxStore";
import { useGetInboxNotificationsCount } from "features/OmniChannel/modules/Inbox/apis/getInboxNotificatificationsCount";
import { useSelector } from "react-redux";
import styles from "features/UILayout/modules/Menu/MenuStyles.module.css";
import { returnEdgeElementsFromParentMenu } from "../../utils/Menu.utils";
import { MenuBadge } from "../MenuBadge/MenuBadge";

export const Communications = ({
  VISIBLE_MENU_ITEMS_CONFIG,
  handleToggle,
  state,
  setState,
  dense,
  config,
  open,
  moveToAnotherMenuItem,
  commonMenuItemLinkProps,
}) => {
  const location = useLocation();
  const totalUnreadNotifications = useInboxStore(
    (state) => state.totalUnreadNotifications
  );

  const { isToolTipTourActive, activeTooltipIndex } = useSelector(
    (state) => state.uiLayout
  );

  // Fetch inbox notifications count
  useGetInboxNotificationsCount();

  const pathNames = [
    `${app_route_ids[app_route_keys.automated_messages]}`,
    `${app_route_ids[app_route_keys.whatsapp_broadcast]}`,
    `/${app_route_ids[app_route_keys.past_emails]}`,
  ];
  const matchResults = pathNames.some((url) =>
    matchPath(location?.pathname, {
      path: url,
    })
  );

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      [TOOGLE_PARENT_MENU_KEYS.COMMUNICATIONS]: matchResults,
    }));
  }, [matchResults]);

  const { firstVisibleItem: firstCommunicationsItem } =
    returnEdgeElementsFromParentMenu({
      parentMenuItemsArr:
        SUB_MENU_CONFIG[TOOGLE_PARENT_MENU_KEYS.COMMUNICATIONS],
      visibleItems: VISIBLE_MENU_ITEMS_CONFIG,
    });

  const toggleCommunicationsMenu = () =>
    handleToggle(TOOGLE_PARENT_MENU_KEYS.COMMUNICATIONS);

  return (
    <div id="Communications">
      <ParentMenu
        handleToggle={toggleCommunicationsMenu}
        isOpen={state.menuCommunications}
        sidebarIsOpen={open}
        name="Communications"
        icon={<ChatRoundedIcon />}
        rightIcon={<MenuBadge text="New" />}
        dense={dense}
        {...(isToolTipTourActive &&
          config[activeTooltipIndex] === firstCommunicationsItem && {
            menuItemClassName: styles.tooltipContainer,
          })}
      >
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.INBOX] && (
          <CustomMenuItemLink
            to={`${app_route_ids[app_route_keys.inbox]}`}
            id={MENU_ITEM_IDS.INBOX}
            primaryText={
              <div className="tw-flex tw-gap-1 tw-items-center tw-justify-center">
                Inbox{" "}
                {!!totalUnreadNotifications && (
                  <div className="tw-flex tw-items-center tw-justify-center tw-rounded-[16px] tw-bg-[#ca0315] tw-px-1 tw-text-white tw-text-center tw-text-[10px] tw-h-4 tw-min-w-4">
                    {totalUnreadNotifications}
                  </div>
                )}
              </div>
            }
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.AUTOMATED_MESSAGES] && (
          <CustomMenuItemLink
            to={`${app_route_ids[app_route_keys.automated_messages]}`}
            id={MENU_ITEM_IDS.AUTOMATED_MESSAGES}
            primaryText="Automated Messages"
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.WHATSAPP_MARKETING] && (
          <CustomMenuItemLink
            to={`${app_route_ids[app_route_keys.whatsapp_broadcast]}`}
            id={MENU_ITEM_IDS.WHATSAPP_MARKETING}
            primaryText="Whatsapp Marketing"
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.EMAIL_MARKETING] && (
          <CustomMenuItemLink
            to={`/${app_route_ids[app_route_keys.past_emails]}`}
            id={MENU_ITEM_IDS.EMAIL_MARKETING}
            primaryText="Email Marketing"
            handleToggleParent={moveToAnotherMenuItem}
            {...commonMenuItemLinkProps}
          />
        )}
      </ParentMenu>
    </div>
  );
};
